<template>
  <div>
    <v-dialog v-if="confirmDelete" v-model="confirmDelete" max-width="360">
      <v-card>
        <v-card-title>{{ $t("delete record") }}?</v-card-title>
        <v-card-text v-if="deleteError === null">
          {{ $t("delete-message", { ip: record.ip }) }}
        </v-card-text>
        <v-card-text v-if="deleteError !== null">
          <span class="error">{{ deleteError }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmDelete = false"
            :disabled="loadingCreate || loadingUpdate || loadingDelete"
            text
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="deleteRecordConfirmed"
            :disabled="loadingDelete || deleteError !== null"
            text
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card elevation="0">
      <validation-observer ref="obs" v-slot="{ errors, invalid }">
        <v-form onSubmit="return false;">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="ip"
                  :name="$t('IP address')"
                  :rules="create ? 'required|ip|partOfIpNet:' + network : ''"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="record.ip"
                    name="ip"
                    :label="$t('IP address')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="!create || readOnly"
                    :clearable="create && !isMobile"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="content"
                  :name="$t('DNS name')"
                  rules="required|max:1024|dns"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="record.content"
                    name="content"
                    :label="$t('DNS name')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="readOnly"
                    :class="classes"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <validation-provider
                  vid="ttl"
                  :name="$t('TTL in seconds')"
                  rules="between:600,86400"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="record.ttl"
                    type="number"
                    name="ttl"
                    :label="$t('TTL in seconds')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :disabled="readOnly"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  vid="disabled"
                  :name="$t('disable record')"
                  v-slot="{ errors, classes }"
                >
                  <v-switch
                    v-model="record.disabled"
                    name="disabled"
                    :label="$t('disable record')"
                    :error-messages="errors"
                    :disabled="readOnly"
                    :class="classes"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!create"
              :disabled="
                loadingDelete || loadingUpdate || $store.getters.isReadOnly
              "
              :loading="loadingDelete"
              @click="deleteRecord()"
              text
            >
              {{ $t("delete") }}
            </v-btn>
            <v-btn
              v-if="create"
              :disabled="loadingCreate"
              @click="$emit('recordCanceled')"
              text
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              v-if="!create"
              color="primary"
              :disabled="
                loadingUpdate ||
                loadingDelete ||
                invalid ||
                readOnly ||
                $store.getters.isReadOnly
              "
              :loading="loadingUpdate"
              @click="updateRecord()"
              text
            >
              {{ $t("update") }}
            </v-btn>
            <v-btn
              v-if="create"
              color="primary"
              :disabled="
                loadingCreate ||
                loadingDelete ||
                invalid ||
                readOnly ||
                $store.getters.isReadOnly
              "
              :loading="loadingCreate"
              @click="createRecord()"
              text
            >
              {{ $t("create") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
const ip = require("@/utils/ip");
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "ReverseDnsRecord",
  mixins: [showErrors, isMobile],
  props: {
    create: {
      type: Boolean,
      default: false,
    },
    network: {
      type: String,
      required: true,
    },
    ip: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ttl: {
      type: Number,
      default: 86400,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmDelete: false,
      deleteError: null,
      loadingCreate: false,
      loadingUpdate: false,
      loadingDelete: false,
      record: {
        ip: this.ip,
        content: this.content,
        ttl: this.ttl,
        disabled: this.disabled,
      },
    };
  },
  watch: {
    "$props.ip": function () {
      this.record.ip = this.ip;
    },
    "$props.content": function () {
      this.record.content = this.content;
    },
    "$props.ttl": function () {
      this.record.ttl = this.ttl;
    },
    "$props.disabled": function () {
      this.record.disabled = this.disabled;
    },
  },
  methods: {
    emitAll: function () {
      this.$emit("update:ip", this.record.ip);
      this.$emit("update:content", this.record.content);
      this.$emit("update:ttl", this.record.ttl);
      this.$emit("update:disabled", this.record.disabled);
    },
    createRecord: function () {
      var that = this;
      this.loadingCreate = true;
      this.record.ip = ip.normalize(this.record.ip);
      this.$http
        .post("services/dns/reverse", this.record)
        .then((response) => {
          that.$emit("recordCreated");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingCreate = false;
        });
    },
    updateRecord: function () {
      var that = this;
      this.loadingUpdate = true;
      this.$http
        .patch("services/dns/reverse/" + this.ip, this.record)
        .then((response) => {
          that.emitAll();
          that.$emit("recordUpdated");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingUpdate = false;
        });
    },
    deleteRecord: function () {
      this.deleteError = null;
      this.confirmDelete = true;
    },
    deleteRecordConfirmed: function () {
      if (this.ip !== null) {
        var that = this;
        this.loadingDelete = true;
        this.$http
          .delete("services/dns/reverse/" + this.ip)
          .then((response) => {
            that.confirmDelete = false;
            that.deleteError = null;
            that.$emit("recordDeleted");
          })
          .catch((err) => {
            that.deleteError = err.message;
          })
          .finally(function () {
            that.loadingDelete = false;
          });
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "delete": "Delete",
    "update": "Update",
    "create": "Create",
    "cancel": "Cancel",
    "IP address": "IP",
    "TTL in seconds": "Time to live in seconds",
    "disable record": "Disable record",
    "DNS name": "DNS name",
    "delete record": "Delete record",
    "delete-message": "Do you really want to delete {ip} record?"
  },
  "de": {
    "delete": "Löschen",
    "update": "Aktualisieren",
    "create": "Anlegen",
    "cancel": "Abbrechen",
    "IP address": "IP Adresse",
    "TTL in seconds": "Gültigkeitsdauer in Sekunden",
    "disable record": "Eintrag deaktivieren",
    "DNS name": "DNS Name",
    "delete record": "Eintrag löschen",
    "delete-message": "Wollen Sie den Eintrag {ip} wirklich löschen?"
  }
}
</i18n>
