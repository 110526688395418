<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-spacer />
      <wiki slug="services-dns-reverse-overview" />
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="networks"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        :custom-filter="customFilter"
        show-expand
        single-expand
        sort-by="network"
        :hide-default-footer="networks.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
      >
        <template v-slot:top>
          <v-text-field
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            :clearable="!isMobile"
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <reverse-dns-details :item="item" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ReverseDnsDetails from "@/components/services/ReverseDnsDetails";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";
const ip = require("@/utils/ip");

export default {
  name: "ReverseDns",
  components: {
    ReverseDnsDetails,
    Wiki,
  },
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  mixins: [isMobile],
  data: () => ({
    networks: [],
    loading: false,
    search: "",
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("IP network"),
          value: "network",
          sort: (a, b) => {
            return ip.compareCIDR(a, b);
          },
        },
        {
          text: this.$i18n.t("IP version"),
          value: "version",
        },
        {
          text: this.$i18n.t("number IPs"),
          value: "length",
        },
        {
          text: this.$i18n.t("first IP"),
          value: "first",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("last IP"),
          value: "last",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.networks = [];
      this.getNetworks();
    },
  },
  methods: {
    customFilter(value, search, item) {
      var addr = ip.parse(search);
      if (addr != null && item.cidr.contains(addr.toString())) return true;
      return (
        item.network.indexOf(search) >= 0 ||
        item.first.indexOf(search) >= 0 ||
        item.last.indexOf(search) >= 0
      );
    },
    getNetworks: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/dns/reverse", {
          params: {
            product_id: this.productId,
          },
        })
        .then((response) => {
          var i = 0;
          that.networks = response.data.map(function (item) {
            // add "id" because Vuetify DataTable expand will not right well wihtout
            i = i + 1;
            item.cidr = ip.createCIDR(item.network);
            item.length = ip.length(item.network);
            item.id = i;
            return item;
          });
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getNetworks();
  },
};
</script>

<i18n>
{
  "en": {
    "reverse DNS networks": "Reverse DNS networks",
    "IP network": "IP network",
    "IP version": "IP version",
    "first IP": "First IP",
    "last IP": "Last IP",
    "number IPs": "Number IPs"
  }, 
  "de": {
    "reverse DNS networks": "Reverse DNS Netze",
    "IP network": "IP Netz",
    "IP version": "IP Version",
    "first IP": "erste IP",
    "last IP": "letzte IP",
    "number IPs": "Anzahl IPs"
  }
}
</i18n>
