<template>
  <v-card class="mt-4 mb-4" outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ item.network }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip v-if="!loading && records.length < item.length" top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              createDialog = true;
              expanded = [];
            "
            :disabled="loading || createDialog || $store.getters.isReadOnly"
            icon
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add IP") }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="records"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        sort-by="ip"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [rbreakpoint, 50],
        }"
        :hide-default-footer="records.length <= rbreakpoint"
        :expanded="expanded"
        show-expand
        single-expand
      >
        <template v-slot:top>
          <v-card>
            <v-text-field
              v-if="records.length > rbreakpoint"
              class="mb-4"
              v-model="search"
              append-outer-icon="mdi-magnify"
              :clearable="!isMobile"
              v-bind:label="$t('search')"
              single-line
              hide-details
            />
            <v-expand-transition>
              <reverse-dns-record
                class="mb-4"
                v-show="createDialog"
                @recordCreated="createRecord"
                @recordCanceled="createDialog = false"
                :create="true"
                :network="network"
                v-bind.sync="newItem"
              />
            </v-expand-transition>
          </v-card>
        </template>

        <template v-slot:item.disabled="{ item }">
          <v-icon v-if="item.disabled === true">mdi-check-box-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <reverse-dns-record
              class="mb-4 mt-4"
              @recordCreated="getRecords()"
              @recordUpdated="expanded = []"
              @recordDeleted="
                getRecords();
                expanded = [];
              "
              v-bind.sync="item"
              :network="network"
              :readOnly="item.read_only"
            />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import ReverseDnsRecord from "@/components/services/ReverseDnsRecord";
import isMobile from "@/utils/mixins/isMobile";
const ip = require("@/utils/ip");

export default {
  name: "ReverseDnsDetails",
  components: {
    ReverseDnsRecord,
  },
  mixins: [isMobile],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      records: [],
      network: this.item.network,
      search: "",
      newItem: {},
      rbreakpoint: 15,
      createDialog: false,
      expanded: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("IP"),
          value: "ip",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("TTL"),
          value: "ttl",
        },
        {
          text: this.$i18n.t("disabled"),
          value: "disabled",
        },
        {
          text: this.$i18n.t("content"),
          value: "content",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$props.item.id": function() {
      this.getRecords();
    },
  },
  methods: {
    getRecords: function() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/dns/reverse/" + this.item.network)
        .then((response) => {
          var i = 0;
          that.records = response.data.records.map(function(item) {
            i = i + 1;
            item.id = i;
            return item;
          });
          that.network = response.data.network;
        })
        .catch((err) => {
          if (err.invalid()) {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    createRecord: function() {
      this.createDialog = false;
      this.newItem = {};
      this.getRecords();
    },
    /*
    updateRecord: function(updatedItem) {
      this.expanded = this.expanded.filter(function(item) {
        return updatedItem.id === item.id;
      });
    }
    */
  },
  mounted: function() {
    this.getRecords();
  },
};
</script>

<i18n>
{
  "en": {
    "IP": "IP",
    "TTL": "TTL",
    "content": "Content",
    "search": "Search",
    "cancel": "Cancel",
    "save": "Save",
    "reverse DNS name": "Reverse DNS name",
    "Input too long!": "Input too long!",
    "disabled": "Disabled",
    "IP": "IP",
    "add IP": "Add IP",
    "search": "Search"
  },
  "de": {
    "IP": "IP",
    "TTL": "TTL",
    "content": "Inhalt",
    "search": "Suchen",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "reverse DNS name": "Reverse DNS Name",
    "Input too long!": "Eingabe zu lang!",
    "disabled": "Ausgeschaltet",
    "IP": "IP",
    "add IP": "IP hinzufügen",
    "search": "Auchen"
  }
}
</i18n>
